<template>
  <div>
    <b-modal
      ref="modalDataCriteriosLuxury"
      :title="$t('Critérios de aplicabilidade Luxury')"
      size="lg"
      :body-class="(iso === 'PT') ? 'p-0' : ''"
      :visible="false"
      :ok-only="true"
      :ok-title="$t('Fechar')"
      ok-variant="outline-secondary"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    >
      <div class="p-2">
        <p v-if="iso === 'ES'">
          {{ $t('O tipo de negócio da angariação deve ser venda ou trespasse.') }}
        </p>

        <p v-if="iso === 'PT'">
          {{ $t('A angariação deve ser do tipo ‘Habitacional’, o tipo de negócio deve ser ‘Venda’ ou ‘Trespasse’, a condição do imóvel deve ser diferente de ‘Ruína’ e os tipos de imóveis, descritos abaixo, devem ter o preço de venda igual ou superior ao descrito.') }}
        </p>

        <p v-if="iso === 'PT' || iso === 'ES'">
          {{ $t('Caso a angariação pertença a um empreendimento, apenas são elegíveis as angariações definidas como ‘Imóveis em destaque’ (no separador ‘Grupos’).') }}
        </p>
      </div>

      <template v-if="iso === 'PT'">
        <app-collapse accordion>
          <app-collapse-item
            v-for="(distritoCriterios, index) in criterios"
            :key="`criterio-${index}`"
            :title="`${distritoCriterios.name}`"
          >
            <p
              v-for="(criterio, criterioIndex) in distritoCriterios.criterios"
              :key="`criterio-${criterioIndex}`"
            >
              <b>{{ criterio.name }}</b>: {{ toCurrency(criterio.criterio) }}
            </p>
          </app-collapse-item>
        </app-collapse>
      </template>

    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue'
import { toCurrency } from '@core-custom/utils/ui'
import { symbolMoney } from '@core-custom/utils/money'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
import useAppConfig from '@core/app-config/useAppConfig'
import { mixFormGeral } from '../../mixins'

export default {
  components: {
    BModal,
    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    Ripple,
  },
  mixins: [mixFormGeral],
  props: {
    iso: {
      type: String,
      default: () => '',
    },
    criterios: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      toCurrency,
      symbolMoney,
    }
  },
  computed: {
    isDark() {
      return this.skin.value === 'dark'
    },
  },
  methods: {
    showModal() {
      return new Promise((resolve, reject) => {
        this.$refs.modalDataCriteriosLuxury.show()
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    hideModal() {
      this.$refs.modalDataCriteriosLuxury.hide()
    },
  },
  setup() {
    const { skin } = useAppConfig()

    return {
      skin,
    }
  },
}
</script>
